<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <header-image :title="headerTitle"></header-image>
    <!-- /头部 -->

    <!-- 体部 -->
    <image-list :urls="urls"></image-list>
    <!-- /体部 -->

    <!-- 尾部 -->
    <Footer></Footer>
    <!-- /尾部 -->

  </div>
</template>
 
<script>
import { get, post } from "@/utils/request";
import Carousel from "../components/Carousel";
  export default {
    name: "index",
    components: {},
    data() {
      return {
        headerTitle:"媒体资源介绍",
        urls: [
          require("@/assets/xyhd/mediainfo_1.png"),
          require("@/assets/xyhd/mediainfo_2.png"),
          require("@/assets/xyhd/mediainfo_3.png"),
          require("@/assets/xyhd/mediainfo_4.png"),
          require("@/assets/xyhd/mediainfo_5.png"),
          require("@/assets/xyhd/mediainfo_6.png"),
          require("@/assets/xyhd/mediainfo_7.png"),
        ]
    };
  },
  created() {},
  methods: {
  },

};
</script>

<style lang='scss'>
  // 体部样式


</style>